<template>
  <div id="choiceDiv2" :style="{'overflow': embedded? 'unset': 'hidden'}">
    <div id="inner-div-grid">
      <Header v-if="!embedded" :showDate="true" :byId="true"  :useruid="this.$route.params.useruid" :title="$t('controller.manage_attendance')"></Header>
      <div id="attendance-div"  :style="{'height': Math.max(windowHeight-84)+'px','grid-template-rows': embedded? '10px 70px 50px auto' :'minmax(auto,33%) 40px 70px auto'}">
        <div v-if="!embedded"><Tip :tipNumber="13"></Tip></div>
        <div></div>
        <div id="registration-wrapper">
          <div>
            <div v-if="visitor_hash != null">{{$t('controller.registration_url')}}:</div>
            <div v-if="visitor_hash != null" class="grid-fit-2column">
              <b id="visitor-link" >{{serverUrl+'/#/visitor/'+visitor_hash}}</b>
              <b-button id="url-copy-button"  v-b-popover.hover.right="$t('controller.copy_link')" variant="clear-blue" v-on:click="copyToClipboard(serverUrl+'/#/visitor/'+visitor_hash)"></b-button>
            </div>
          </div>
          <div v-if="isCorporateConnections" id="send-links-button"  variant="clear-blue" v-on:click="sendSMS">{{$t('controller.send_meeting_assistant')}}</div>
        </div>
          <div>
              <div id='visitor-header' :style="{'grid-template-columns': 'auto'+(visitorsubs.length>0? ' max-content max-content' : '')+' max-content max-content'}">
                <div class="header-title-text" >{{$t('controller.visitor_table')}}</div>
                <b-button v-if="visitorsubs.length>0" v-on:click="showClearAttendance=true;" class="action-button visitor-option-button" variant="clear-blue" ><div  class="delete-button auto-margin"></div><span >{{$t('controller.clear_all')}}</span></b-button>
                <b-button v-if="visitorsubs.length>0" v-on:click="showSetAllPresent=true;" class="action-button visitor-option-button" variant="clear-blue"><div  class="save-button auto-margin"></div><span >{{$t('controller.set_present')}}</span></b-button>
                <b-button  v-on:click="showImportVisitors=true;" class="action-button visitor-option-button" variant="clear-blue" ><div  class="import-button auto-margin"></div><span >{{$t('global.button_import')}}</span></b-button>
                <b-button  v-on:click="newVisitor" class="action-button visitor-option-button" variant="clear-blue" ><div  class="new-button auto-margin"></div><b>{{$t('global.button_new')}}</b></b-button>
              </div>
            <b-table v-show="visitorsubs.length>0" class="attendance-table" small bordered :tbody-tr-class="rowClass" :items="visitorsubs" :fields="fields">
              <template v-slot:cell(name)="data" >
                <div v-on:click.stop="editVisitor(data.item)">
                  <b>{{data.item.name}}</b>{{ data.item.company && data.item.company.length>0? " > "+ data.item.company: ""}}
                </div>
              </template>
             <template v-slot:cell(type)="data">
            <span>{{ data.item.type==1? $t("controller.substitute_text") : (data.item.type==3? (isCorporateConnections? $t("controller.guest_text") : $t("controller.visitor_text")) : (data.item.type==5? $t("controller.regional_text") : $t("controller.observer_text")))}}</span>
          </template>
           <template v-slot:cell(status)="data">
             <div v-on:click.stop="changeStatus(data.item)">
            <b>{{ data.item.present==0?$t("controller.registered_visitor"): $t("controller.present_visitor")}}</b>
             </div>
          </template>
              <template v-slot:cell(visits)="data">
                  {{data.item.visits}}
              </template>
              <template v-slot:cell(inperson_or_virtual)="data">
                {{ data.item.inperson_or_virtual==0?$t("attendance.inperson_text"): $t("attendance.virtual_text")}}
              </template>
              <template v-slot:cell(linked_member)="data">
                  <span class="grey-text">{{data.item.member_id>0 && data.item.type != 5? ((data.item.type==1? $t('controller.subbing_for') : $t('controller.invited_by'))) : ""}}</span> {{data.item.member_id>0? data.item.linked_member : ""}}
              </template>
              <template v-slot:cell(photo_verification)="data">
                  <div v-if="!isCorporateConnections && data.item.type==3 && data.item.present==1" :style="{'color': (data.item.photo_verification==3? '#CF2030' : '#009900')}" v-on:click.stop="getVisitorPhoto(data.item)">{{$t('controller.photo_verification'+data.item.photo_verification)}}</div>
                  <div v-if="isCorporateConnections && data.item.type==3 && data.item.present==1" :style="{'color': (data.item.photo_small==null? '#CF2030' : '#009900')}" v-on:click.stop="getVisitorPhoto(data.item)">{{data.item.photo_small==null? $t('controller.photo_not_set') : $t('controller.photo_set')}}</div>
              </template>
            </b-table>
            <div id="member-header">
                <div class="header-title-text">{{$t('controller.member_table')}}</div>
                <b-button v-on:click="newMember" class="action-button" variant="clear-blue visitor-option-button"><div class="new-button auto-margin"></div><b>{{$t('global.button_new')}}</b></b-button>
            </div>
            <b-table v-show="attendance_members.length>0" class="table-hover-new clickable attendance-table" striped small bordered :items="attendance_members" :fields="fields2" v-on:row-clicked="editMember">
              <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
              </template>
              <template v-slot:cell(status)="data">
                {{data.item.type_text +" @ "+ (data.item.arrival != null? $d(new Date(data.item.arrival), 'time12') : "")}}
              </template>
            </b-table>

            <b-modal static="true" centered id="slide-modal-3" v-model="showPreviewImport" :title="$t('import.import_title')" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
              <div id="preview-visitors">
                 <table class="table b-table table-hover-new table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="tableHeader">{{$t('import.import_header')}}</th>
                      <th class="tableHeader">{{$t('import.name_header')}}</th>
                      <th class="tableHeader">{{$t('import.company_header')}}<br/>{{$t('import.profession_header')}}</th>
                      <th class="tableHeader">{{$t('import.email_header')}}<br/>{{$t('import.phone_header')}}</th>
                      <th class="tableHeader">{{$t('import.website_header')}}</th>
                      <th class="tableHeader">{{$t('import.invitedby_header')}}<br/>{{$t('import.matched_header')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="v in previewVisitors" :key="v.name" :style="{background: (v.member_name.length>0 && v.member_id==0)? '#e6655a' : ''}">
                      <td>
                        <b-form-checkbox  class="fit-content auto-margin" v-model="v.import">
                        </b-form-checkbox>
                      </td>
                      <td>{{v.name}}</td>
                      <td>{{v.company}}<br/>{{v.profession}}</td>
                      <td>{{v.email}}<br/>{{v.phone}}</td>
                      <td>{{v.website}}</td>
                      <td>
                        <div v-if="v.type==3">
                          <div>{{v.member_name}}</div>
                          <b-form-select v-if="v.member_name.length>0" v-model="v.member_id" :options="members" value-field="id" text-field="name" class="form-box-input medium-width-input" />
                        </div>
                        <div v-if="v.type==5">{{$t('controller.regional_text')}}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="preview-note">
                  *{{$t('controller.preview_note')}}
                </div>
              </div>
              <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button  class="action-button" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
              </div>
            </b-modal>

             <div v-if="showAlert">
              <b-alert variant="danger" dismissible :show="showAlert"  @dismissed="showAlert=false">
                {{alertText}}
              </b-alert>
            </div>
            <div v-if="showSuccess">
              <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
                {{$t('controller.import_success')}}
              </b-alert>
            </div>
            <div v-if="showUpload">
              <b-alert id="upload-alert" variant="warning" :show="showUpload">
                {{$t('preparer.uploading_text')}}
                <b-spinner id="upload-alert-spinner" label="Uploading" variant="warning"></b-spinner>
              </b-alert>
            </div>
           
            <b-modal id="slide-modal" static="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered v-model="showSendSMS" :title="$t('controller.send_assistants')">
                <div id="send-assistants-wrapper">
                  <div id="send-assistants-options" class="fit-width">
                    <b-form-radio v-model="sendSMSAll" value="1">
                          {{$t('controller.send_all')}}
                      </b-form-radio>
                      <b-form-radio  v-model="sendSMSAll" value="0">
                          {{$t('controller.select_recipients')}}
                      </b-form-radio>
                    </div>
                    <div id="recipient-table-wrapper">
                      <b-table id="recipient-table" small :items="recipients" :fields="sendSMSAll==1? recipientFields : selectRecipientFields">
                        <template v-slot:cell(select)="data" >
                          <b-form-checkbox  v-model="data.item.send">
                          </b-form-checkbox>
                        </template>
                        <template v-slot:cell(name)="data" >
                          {{data.item.name}} > {{data.item.company}}
                        </template>
                        <template v-slot:cell(type)="data">
                          {{data.item.type==3? $t("controller.guest_text") : (data.item.type==1? $t('controller.sub_text') :(data.item.type==1? $t('controller.regional_text') : $t('controller.member_text')))}}
                        </template>
                        <template v-slot:cell(method)="data">
                          <span v-b-popover.hover.top="$t('controller.send_unavailable')" :style="{'color': (data.item.preferred_contact==0 && data.item.mobile_phone.length>0) || (data.item.preferred_contact==1 && data.item.email.length>0)  || (data.item.preferred_contact==2 && data.item.email.length>0 && data.item.mobile_phone.length>0)? '': 'red' }">{{data.item.preferred_contact==0? $t("member.sms_mobile") : (data.item.preferred_contact==1? $t("member.email_link") : $t("member.sms_email"))}}</span>
                        </template>
                      </b-table>
                    </div>
                </div>
              <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="showSendSMS = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button class="action-button" v-on:click="sendLinks" variant="clear-blue"><div class="send-button"></div><span>{{$t('global.button_send')}}</span></b-button>
              </div>
            </b-modal>
            <b-modal static="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered v-model="showMemberAttendance" >
              <form v-on:submit.prevent="saveMember">
                  <div id="member-type-options" class="fit-width grid-fit-2column auto-margin">
                   <b-form-radio v-model="currentMember.type" value="-1">
                        {{$t('controller.absent_text')}}
                    </b-form-radio>
                    <b-form-radio  v-model="currentMember.type" value="2">
                        {{$t('controller.late_text')}}
                    </b-form-radio>
                  </div>
                  <div v-if="currentMember" id="member-select-wrapper" class="form-box">
                    <span class="form-box-name" >{{$t('controller.member_text')}}</span><b-form-select :disabled="currentMember.type==0" v-model="currentMember.member_id" :options="members_present" value-field="id" text-field="name" class="form-box-input medium-width-input"/>
                  </div>
              </form>
              <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="showMemberAttendance = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button class="action-button" v-if="currentMember && currentMember.id>0" v-on:click="deleteMember" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-button :disabled="currentMember.type==0" class="action-button" v-on:click="saveMember" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
              </div>
            </b-modal>
            <b-modal static="true" centered v-model="showVisitorAttendance" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
                <form v-on:submit.prevent="saveVisitor">
                    <div id="visitor-type-options" class="fit-width">
                      <b-form-radio v-model="currentVisitor.type" value="3">
                          {{isCorporateConnections? $t("controller.guest_text") : $t('controller.visitor_text')}}
                      </b-form-radio>
                      <b-form-radio v-if="!isCorporateConnections"  v-model="currentVisitor.type" value="4">
                          {{$t('controller.observer_text')}}
                      </b-form-radio>
                      <b-form-radio  v-model="currentVisitor.type" value="1">
                          {{$t('controller.sub_text')}}
                      </b-form-radio>
                    <b-form-radio  v-model="currentVisitor.type" value="5">
                          {{$t('controller.regional_text')}}
                      </b-form-radio>
                    </div>
                    <div v-if="currentVisitor && currentVisitor.type != 5 && currentVisitor.type != 0" class="visitor-box" >
                        <span class="form-box-name">{{(currentVisitor.type==0? "" : (currentVisitor.type==1? $t('controller.substitute_text') + " " : (currentVisitor.type==3? $t('controller.visitor_text')  + " ": $t('controller.observer_text') + " ")))+$t('controller.name_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                        <span class="form-box-name">{{$t('controller.company_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name">{{$t('controller.profession_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.profession" type="text" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name">{{(currentVisitor.type==0? $t('controller.member_text') : (currentVisitor.type==1? $t('controller.subbing_for') + " " :  $t('controller.invited_by') ))}}</span><b-form-select :disabled="currentVisitor.type==0" v-model="currentVisitor.member_id" :options="members2" value-field="id" text-field="name" class="form-box-input medium-width-input" />
                        <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.attendance_text')}}</span><b-form-select v-if="isCorporateConnections" :disabled="currentVisitor.type==0" v-model="currentVisitor.inperson_or_virtual" :options="attendanceTypeOptions" class="form-box-input medium-width-input"/>
                        <span class="form-box-name">{{$t('controller.phone_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.phone" type="tel" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.mobile_phone')}}</span><b-form-input v-if="isCorporateConnections" :disabled="currentVisitor.type==0" v-model="currentVisitor.mobile_phone" type="tel" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name">{{$t('controller.email_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.email" v-on:blur="pullPrevious(currentVisitor)" type="email" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name first-column" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                        <b-form-select v-if="isCorporateConnections" class="form-box-input medium-width-input"  v-model="currentVisitor.preferred_contact" :options="contactMethods"/>
                        <span class="form-box-name">{{$t('controller.website_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.website" type="text" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.linkedin_url')}}</span><b-form-input v-if="isCorporateConnections" :disabled="currentVisitor.type==0" v-model="currentVisitor.linkedin_url" type="text" class="form-box-input medium-width-input"></b-form-input>
                        <span class="form-box-name">{{$t('controller.attendance_status')}}</span>
                        <div class="grid-auto-2column">
                          <b-form-radio v-model="currentVisitor.present" :value="1" class="auto-margin">
                          {{$t('controller.present_text')}}
                        </b-form-radio>
                        <b-form-radio v-model="currentVisitor.present" :value="0" class="auto-margin">
                          {{$t('controller.registered_visitor')}}
                        </b-form-radio>
                        </div>
                    </div>
                    <div v-if="currentVisitor && currentVisitor.type == 5" id="regional-contact-box" class="visitor-box">
                      <span class="form-box-name">{{$t('controller.regional_contact')}}</span><b-form-select :disabled="currentVisitor.type==0" v-model="currentVisitor.member_id" :options="contacts" value-field="id" text-field="name" class="form-box-input medium-width-input" />
                      <span class="form-box-name">{{$t('controller.attendance_status')}}</span>
                      <div class="grid-auto-2column">
                          <b-form-radio v-model="currentVisitor.present" value="1" class="auto-margin">
                          {{$t('controller.present_text')}}
                        </b-form-radio>
                        <b-form-radio v-model="currentVisitor.present" value="0" class="auto-margin">
                          {{$t('controller.registered_visitor')}}
                        </b-form-radio>
                        </div>
                    </div>
                    
                    <div v-if="isCorporateConnections && currentVisitor.type != 5" class="visitor-box">
                      <span class="form-box-name">{{$t('controller.photo_header')}}</span>
                      <ImageSelector ref="imageSelector" :imageObject="currentVisitor" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :circular="true"/>
                    </div>
                    <b-alert variant="danger" dismissible :show="showMemberCreateAlert"  @dismissed="showAlert=false">
                      {{memberCreateAlertText}}
                    </b-alert>
                    <b-alert variant="success" dismissible :show="showMemberCreateSuccess"  @dismissed="showAlert=false">
                      {{memberCreateAlertText}}
                    </b-alert>
                    <b-button id="create-member-button" v-if="(isCorporateConnections && currentVisitor && currentVisitor.id>0 && currentVisitor.type != 5 && currentVisitor.email)" class="action-button" v-on:click="createMember(currentVisitor)" variant="clear-blue"><div class="save-button"></div><span>{{$t('controller.create_member')}}</span></b-button>
                </form>
                <div v-if="showVisitorError">
                    <b-alert variant="danger"
                             dismissible
                             :show="showVisitorError"
                             @dismissed="showVisitorError=false">
                        {{visitorErrorText}}
                    </b-alert>
                </div>
                <div slot="modal-footer" class="submit-box-2">
                  <b-button class="action-button" v-on:click="showVisitorAttendance = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                    <b-button v-if="currentVisitor && currentVisitor.id>0" class="action-button" v-on:click="deleteVisitor" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                    <b-button :disabled="currentVisitor.type==0" class="action-button" v-on:click="saveVisitor" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                </div>
            </b-modal>
            <b-modal static="true" centered v-model="showOptions" :title="$t('controller.options_text')">
              <div class="center-text">
              <div class="option-title">{{$t('controller.language_text')}}</div>
              <div class="fit-content header-language-options">
                <b-button v-for="language in languageOptions" :key="language.id"  :class="{'action-button' : true, 'active-flag': $i18n.locale == language.abbrev}" class="language-flag"  v-on:click="loadLanguageAsync(language.abbrev)" variant="clear-blue" ><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-button>
              </div>
            </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="showOptions = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal static="true" centered v-model="showUsePreviousData" :title="$t('controller.previous_visitor_title')">
              <div class="center-text">
                <div class="option-title">{{$t('controller.previous_visitor')}}</div>
              </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="showUsePreviousData = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
              <b-button class="action-button" v-on:click="copyPreviousVisitorData" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal static="true" centered v-model="showClearAttendance" :title="$t('controller.clear_day_title')">
              <div class="center-text">
              {{$t('controller.clear_day_text')}}
            </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="showClearAttendance = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="clearAttendance" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal static="true" centered v-model="showSetAllPresent" :title="$t('controller.set_all_title')">
              <div class="center-text">
              {{$t('controller.set_all_present')}}
            </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="showSetAllPresent = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="setAllPresent" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal static="true" id="slide-modal-2" centered v-model="showImportVisitors" :title="$t('controller.import_header')" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
             <div id="import-wrapper">
               <Tip :tipNumber="16"></Tip>
              <div id="import-form-box" class="form-box">
                <b-button id="import-file-select" variant="blue" v-on:click="openDialog">{{$t('import.select_file')}}</b-button>
                <b-form-file id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange($event)" plain hidden></b-form-file>
                <div class="auto-margin">
                  {{file.name}}
                </div>
                <div id="import-note">
                  *{{$t('controller.import_note')}}
                </div>
              </div>
            </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="showImportVisitors = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal static="true" id="system-modal"  centered v-model="showPhotoVerify" :title="$t('controller.approve_photo')" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
             <div id="visitor-image-info-wrapper">
               <img id="visitor-image" :src="currentVisitor.photo"/>
               <div id="visitor-image-info-grid">
                 <b id="visitor-name">{{currentVisitor.name}}</b>
                 <span id="visitor-company">{{currentVisitor.company}}</span>
                 <b>{{currentVisitor.profession}}</b>
                 <span>{{currentVisitor.email}}</span>
                 <span>{{currentVisitor.website}}</span>
               </div>
            </div>
            <div slot="modal-footer" class="fit-content attendance-footer-style">
              <b-button class="action-button" v-on:click="verifyPhoto(2)" variant="clear-blue"><div class="save-button"></div><span>{{$t('controller.button_approve')}}</span></b-button>
              <b-button class="action-button" v-on:click="verifyPhoto(3)" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('controller.button_reject')}}</span></b-button>
            </div>
          </b-modal>
          </div>
        </div>
     <Footer :useruid="this.$route.params.useruid"></Footer>
    </div>
  </div>
</template>

<style scoped lang="scss">
#import-file-select{
  width:200px;
}
#import-form-box{
  grid-template-columns: 50% 50%;
}
#import-wrapper{
  max-width: 600px;margin: auto;
}
#import-note{
  grid-column: 1/3; margin-top: 20px;font-size:12px;
}
#visitor-company{
  font-size:24px;
}
#visitor-name{
  font-size:26px;color:#CF2030;
}
#visitor-image-info-grid{
  font-size:20px;text-align:center;display: grid;grid-template-rows: repeat(6,max-content);margin-top: 50px;
}
#visitor-image-info-wrapper{
  max-width: 600px;margin: auto;display:grid; grid-template-columns: auto auto;
}
#visitor-image{
  width:100%;height:100%;object-fit: contain; max-height:300px; margin-left:10px;
}
#create-member-button{
  margin:auto;margin-top: 30px;
}
#regional-contact-box{
  margin-top:20px;
}
.visitor-box{
  display:grid;grid-template-columns: 40% 60%;
}
.attendance-footer-style{
  display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;
}
#visitor-type-options{
  margin:auto; display:grid; grid-template-columns: max-content max-content max-content max-content; color: #999;font-weight: bold;column-gap: 20px;
}
#member-select-wrapper{
  grid-template-columns: 30% 70%;border:none;
}
#member-type-options{
  color: #999;font-weight: bold;column-gap: 20px;
}
#recipient-table-wrapper{
  max-height:300px;overflow-y:auto;
}
#recipient-table{
  font-weight: 300;
}
#send-assistants-options{
  display:grid;grid-template-columns:auto auto;margin:auto;grid-gap:10px;
}
#upload-alert-spinner{
  float:right;
}
#upload-alert{
  line-height:30px;
}
#preview-note{
  margin-top: 20px;font-size:12px;
}
.header-title-text{
  font-size:20px;
}
#send-assistants-wrapper{
  margin:auto; display:grid; grid-template-columns: auto; color: #999;font-weight: bold;grid-gap:10px;
}
#member-header{
  display:grid; grid-template-columns: auto max-content;width:100%;background: #fafafa; padding: 5px;margin-top:50px;height: 50px;line-height: 40px;
}
#registration-wrapper{
  display: grid; grid-template-columns: auto max-content;width:100%;
}
.attendance-table{
  border:none;
}
.visitor-option-button{
  margin:auto;background: #fafafa; border:none;
}
#send-links-button{
  border: 2px solid #FFD700;
    border-radius: 10px;
    color:black;
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    width: 300px;
    text-align: center;
    font-weight: bold;
    height: 40px;
    font-weight: bold;
}
#url-copy-button{
  height:20px;padding:0px;margin-left:10px;
  width:20px;
  background-image: url("../assets/icon-copy-"+$VUE_APP_IMAGE_MOD+".png");
  background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}
#visitor-header{
  display:grid; width:100%;background: #fafafa; padding: 5px;line-height: 40px;    column-gap: 50px;
}
 #preview-visitors{
   max-height:500px; overflow: auto;
 }
.go-controller-button{
  background-image: url("../assets/Device-Controller-Lit.png");
}
.btn-outline-gray {
    color: #888;
    background-color: transparent;
    border-color: #888;
  }

    .btn-outline-gray:hover {
      color: #fff;
      background-color: rgb(39,170,226);
      border-color: rgb(39,170,226);
    }
.submit-button-atten {
    width: 150px;
    margin: auto;
    height: 40px;
    font-weight: bold;
  }

#controller-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);height:50px;
}
  .size-button {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px 15px;
    background-color: #fff;
    border: none;
  }
  @media (max-width: 767px) {
    #nav-text-con{
      display: none;
    }
  }
  #nav-text-con {
    margin: auto;
  }
  #note-date {
    color: #47afc6;
    font-weight: bold;
    text-align: center;
    grid-column: 2;
  }
  #time-diff {
    text-align: left;
  }
  #inner-div-grid {
    grid-template-rows: 120px auto 30px;
    height: 100%;
  }

  .controller-button {
    font-weight: 700;
    height: 100%;
    font-size: 9px;
    max-width: 100px;
    max-height: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #attendance-div {
    grid-column: 1/3;
    display: grid;
    grid-template-rows: minmax(auto,33%) 10px 20px 50px auto;
    text-align: left;
    overflow: auto;
    padding: 10px 3%;
  }
  #choiceDiv2 {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
</style>
<script>
import favicon from "@/assets/favicon-cc.png";
  import router from "../router"
  
  export default {
    name: "ControllerAttendance",
    computed: {
      controllerStyle: function () {
        var s = {};
        s["grid-template-columns"] = (this.windowWidth<768 ? "100%" : this.windowWidth>=768 && this.windowWidth<1160? "50% 50%" : "34% 33% 33%");
        s["grid-template-rows"] = (this.windowWidth>=768 && this.windowWidth<1160 ? "50% 50%" : "100%");
        return s;
      }
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          document.title = this.$t('global.document_title_attend') +" "+ this.permissions.organization.name;
          this.fields[0].label = this.$t('controller.name_company_header');
          this.fields[1].label = this.$t('controller.status_header');
          this.fields[2].label = this.$t('controller.visit_number');
          this.fields2[0].label = this.$t('controller.name_header');
          this.fields2[1].label = this.$t('controller.status_header');


          this.members[0].name =  this.$t('controller.none_option');
          this.members2[0].name =  this.$t('controller.none_option');
        },
        deep: true
      }
    },
    props: {embedded: {type: Boolean, default: false}
    },
  data() {
    return {
      attendanceTypeOptions: [{value: 0, text: this.$t('assistant.attendance_inperson')},{value: 1, text: this.$t('assistant.attendance_virtual')}],
      showMemberCreateAlert: false,
      memberCreateAlertText: "",
      showMemberCreateSuccess: false,
      showUsePreviousData: false,
      previousInfo: null,
      recipientFields: [{ key: 'name',
          label: this.$t('controller.name_company_header'),
        },
        { key: 'type',
              label: this.$t('controller.type_header'),
            },
         { key: 'method',
          label: this.$t('controller.method_header'),
        }],
        selectRecipientFields: [{ key: 'select',
          label: this.$t('controller.send_header'),
        },{ key: 'name',
          label: this.$t('controller.name_company_header'),
        },
        { key: 'type',
              label: this.$t('controller.type_header'),
            },
         { key: 'method',
          label: this.$t('controller.method_header'),
        }],
      contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.email_link")},{value:2, text: this.$t("member.sms_email")}],
      showSendSMS: false,
      sendSMSAll: 1,
      smsRecipients: [],
      recipients: [],
      showPhotoVerify: false,
      allow_visitor_photos: 0,
      serverUrl: "",
      visitor_hash: "",
      contacts: [],
      showSetAllPresent: false,
      showClearAttendance: false,
     showImportVisitors: false,
        alertText: "",
        file: { name: "" },
        showPreviewImport: false,
        previewVisitors: [],
      showAlert: false,
      showSuccess: false,
      showUpload: false,
      from_controller:0,
      meet_start: '',
      paymentOptions: [{id: 0, name: this.$t('controller.payment_option0')},{id: 1, name: this.$t('controller.payment_option1')},{id: 2, name: this.$t('controller.payment_option2')}],
      client: {},
      id_string: "",
      today: new Date(),
      current_time: new Date(),
      time_difference: "",
      total_difference: 0,
      windowWidth: 1000,
      windowHeight: 1000,
      fields: [
         { key: 'name',
          label: this.$t('controller.name_company_header'),
          thStyle: { "background-color":  "#fafafa","color": "#666", "border":"none","font-weight": "300"},
          tdClass: "td-hover-new"
        },
        { key: 'type',
              label: this.$t('controller.type_header'),
              thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
              tdClass: "actions-centre"
            },
         { key: 'status',
          label: this.$t('controller.status_header'),
          thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
          tdClass: "actions-centre td-hover-new"
        },
           { key: 'visits',
            label: this.$t('controller.visit_number'),
            thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none","font-weight": "300"},
            tdClass: "actions-centre"
          },
           { key: 'linked_member',
            label: "",
            thStyle: { "background-color": "#fafafa","color": "#666", "border":"none","font-weight": "300" }
          }
      ],
      fields2: [
         { key: 'name',
          label: this.$t('controller.name_header'),
          thStyle: { "background-color":  "#fafafa","color": "#666", "border":"none","font-weight": "300" }
        },
         { key: 'status',
          label: this.$t('controller.status_header'),
          thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none","font-weight": "300" }
        }
      ],
      attendance_members: [],
      members_present: [],
      visitorsubs: [],
      selectedMember: -1,
      selectedVisitor: -1,
      currentMember: { type: 0,type_text: "", arrival: null },
      currentVisitor: { member_id: 0, type: 0, photo_verification: 0, photo: null, photo_small: null, linkedin_url: ""},
      member_photo: null,
        showMemberAttendance: false,
        showVisitorAttendance: false,
      date_index: 0,
      showVisitorError: false,  
      visitorErrorText: "",
      showRegional: false,
    members:[],
    members2: [],
    showOptions: false,
    languageOptions: [],
    useruid: ""
    }
  },
  sockets: {
    attendance_refresh(data){
        this.getDates();
    },
    connect(){
      this.$socket.emit("register_attendance",{ id_string : this.id_string });
    }
  },
    methods: {
      sendSMS(){
        this.showSendSMS = true;
        this.$http.post("/api/organization/assistant/attendance/get/select", {day: this.day}).then((response) => {
            if(response.data.success){
              for(var i=0; i<response.data.recipients; i++){
                response.data.recipients[i].send = false;
              }
                this.recipients = response.data.attendance;
            }
        }).catch(() => {
           
        });
      },
      sendLinks(){
        this.smsRecipients = this.recipients.filter(v => v.send);
        this.$http.post("/api/organization/assistant/sendlinks", {sendAll: this.sendSMSAll, recipients: this.smsRecipients}).then((response) => {
          this.showSendSMS = false;
            if(response.data.success){
                this.$bvToast.toast(this.$t('controller.links_sent'), {
                    variant: 'success',
                    solid: true
                });
            }
        }).catch(() => {this.showSendSMS = false;
        });
      },
      rowClass(item) {
        if (!item) return
        if (item.present>0) return 'green-row'
      },
      changeStatus(item){
        item.present = 1-item.present;
        this.updateAttendance(item, false);
      },
      getServerUrl(){
        this.$http.post("/api/organization/get/url", {}).then((response) => {
            if (response.data.success === true) {
              this.serverUrl = response.data.serverUrl;
            }
          }).catch(() => { });
      },
       onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];

        this.importVisitors();
      },
      importVisitors() {
        this.showImportVisitors = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";

        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("useruid", this.useruid);
        this.$http.post("/api/organization/visitors/import/excel"+(this.useruid==''? "" : "/byuseruid"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewVisitors= response.data.visitors;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewVisitors = [];
        this.showPreviewImport = false;
        this.file = { name: "" };
      },
      importChanges() {
          var vs = this.previewVisitors.filter(g => g.import);
          if(vs.length>0){
            this.$http.post("/api/organization/visitors/import/all"+(this.useruid==''? "" : "/byuseruid"), { visitors: vs, day: this.day, useruid: this.useruid}).then((response) => {
            if (response.data.success === true) {
              this.showPreviewImport = false;
              this.getDates();
            }
          }).catch(() => { });
        }else{
          this.showPreviewImport = false;
        }
          
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
      copyToClipboard(str){
          const el = document.createElement('textarea');
          el.value = str;
          document.body.appendChild(el);
          el.select();
          el.setSelectionRange(0, 99999);
          document.execCommand('copy');
          document.body.removeChild(el);
      },
       getLanguages(){
        this.$http.post("/api/organization/languages"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            for(var i=0; i<response.data.languages.length; i++){
                this.languageOptions.push(response.data.languages[i]);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      controllerClick(){
        router.push("/controller/view");
      },
      attendanceAuth(){
        var self = this;
        this.checkInterval = setInterval(function () {
          self.current_time = new Date();
          if (self.current_section != null) {
            var stime = new Date(new Date().toDateString() + " " + self.$d(self.current_section.start_time, 'full_time'));
            var ctime = new Date();
            self.total_difference = (stime.getTime() - ctime.getTime()) / 1000;
            var diff = Math.abs(self.total_difference);
            var hours = Math.floor(diff / 3600);
            diff -= hours * 3600;
            var mins = Math.floor(diff / 60);
            diff -= mins * 60;
            var secs = Math.floor(diff);
            self.time_difference = (self.total_difference<0? "-" : "") + (hours > 0 ? hours + "h " : "") + (mins > 9 ? mins : "0" + mins) + "m " + (secs > 9 ? secs : "0" + secs)+"s";
          }
        }, 50);
        if(this.useruid == ""){
          this.genericAuthenticate("/api/auth/attendance", "/login",function(){
          document.title = self.$t('global.document_title_attend') +" "+ self.permissions.organization.name;
              self.$http.post("/api/client/get"+(self.useruid==''? "" : "/byuseruid"), {controller: true,useruid: self.useruid}).then((response) => {
                if (response.data.id_string) {
                    //self.client.socket = self.$io({transports: ['websocket'], upgrade: false});
                    self.id_string = response.data.id_string;
                    self.$socket.emit("register_attendance",{ id_string : self.id_string });
                   
                }
              }).catch((errors) => {
                console.log(errors);
              });
        });
        }else{
          document.title = self.$t('global.document_title_attend') +" "+ self.permissions.organization.name;
              self.$http.post("/api/client/get"+(self.useruid==''? "" : "/byuseruid"), {controller: true,useruid: self.useruid}).then((response) => {
                if (response.data.id_string) {
                    //self.client.socket = self.$io({transports: ['websocket'], upgrade: false});
                    self.id_string = response.data.id_string;
                    self.$socket.emit("register_attendance",{ id_string : self.id_string });
                    
                }
              }).catch((errors) => {
                console.log(errors);
              });
        }
        
      },
      getContacts() {
        this.$http.post("/api/attendance/contacts/assigned"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          for(var i=0; i<response.data.contacts.length;i++){
            response.data.contacts[i].only_name = response.data.contacts[i].name;
            response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name.length>0? " ("+response.data.contacts[i].contact_name+")" : "");
          }
          this.contacts = response.data.contacts;
            Array.prototype.push.apply(this.members2,response.data.contacts);
            Array.prototype.push.apply(this.members,response.data.contacts);
        }).catch((errors) => {console.log(errors);});
      },
      getMembers() {
        this.$http.post("/api/attendance/members"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
            this.members2 = response.data.members.slice();
            this.members2.unshift({ id: 0, name: this.$t('controller.none_option') });
            this.members = response.data.members.slice();
            this.members.unshift({ id: 0, name: this.$t('controller.none_option') });
            this.getContacts();
            this.redoMembersPresent();
        }).catch(() => { });
      },
      onResize() {
        if (document.getElementById("choiceDiv2")) {
          this.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
          this.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
        }
      },
      getAttendance(date) {
        this.day = date;
        this.current_date = new Date("" + this.day.split("-").join("/") + " 00:01");
        this.org = this.$route.params.org ? this.$route.params.org : this.permissions.organization.id;
        this.visitorsubs = [];
        this.$http.post("/api/organization/attendance/day"+(this.useruid==''? "" : "/byuseruid"), { date: this.day, org: this.org, displayAll: true, useruid: this.useruid }).then((response) => {
          var tempmembers = [];
          if (response.data.members) {
            tempmembers = response.data.members;
            for (var i = 0; i < tempmembers.length; i++) {
              var t = tempmembers[i].type;
              tempmembers[i].type_text = (t == 2 ? this.$t('controller.late_text') : this.$t('controller.absent_text'));
            }
          }
          this.attendance_members = tempmembers;
          this.attendance_members.sort((a, b) => (a.name> b.name) ? 1 : -1);
          if (response.data.visitorsubs) {
            this.visitorsubs = response.data.visitorsubs;
            this.visitorsubs.sort((a, b) => (a.name> b.name) ? 1 : -1);
          }
          this.redoMembersPresent();
        }).catch((errors) => {console.log(errors);});
      },
      getDates() {
        this.$http.post("/api/organization/get/nextmeeting"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.today = new Date(response.data.day.split("-").join("/")+' 00:00:01');
            this.getAttendance(response.data.day);
          }
        }).catch(() => { });
      },
      saveMember() {
        this.showMemberAttendance = false;
        if(this.currentMember.member_id==0){
          return;
        }
        var t = this.currentMember.type;
         this.currentMember.type_text= (t == 2 ? this.$t('controller.late_text') : this.$t('controller.absent_text'));
        var member = this.currentMember;
         if (this.currentMember.member_id > 0) {
          for (var i = 0; i < this.members2.length; i++) {
            if (this.members2[i].id == this.currentMember.member_id) {
              this.currentMember.name = this.members2[i].name;
              break;
            }
          }
        }
         if (!(this.currentMember.id && this.currentMember.id > 0)) {
          this.attendance_members.push(this.currentMember);
          this.attendance_members.sort((a, b) => (a.name> b.name) ? 1 : -1);
        }
        this.updateAttendance(member,true);
        this.currentMember = { type: -1 };
       
      },
      newVisitor() {
        this.currentVisitor = {
          arrival: null, linked_member: "", init_email: "",
          name: "", email: "", id: 0, phone: "", website: "", profession: "", mobile_phone: "", preferred_language: "", preferred_contact: 0,
          company: "", member_id: 0, deleted: 0, type: 3, payment: 0, present: 1, photo_verification: 0, photo: null, photo_small: null, linkedin_url: "",inperson_or_virtual: 0
        };
        this.showMemberCreateAlert = false;
        this.showMemberCreateSuccess = false;
        this.showVisitorAttendance = true;
        this.selectedVisitor = -1;
      },
      newMember() {
        this.currentMember = {
          arrival: null, name: "", email: "", id: 0, phone: "", website: "", profession: "", mobile_phone: "", preferred_language: "", preferred_contact: 0,
          company: "", member_id: 0, deleted: 0, type: 0, payment: 0, present: 1, photo_verification: 0, photo: null, photo_small: null, linkedin_url: "",inperson_or_virtual:0
        };
        this.redoMembersPresent();
        this.showMemberAttendance = true;
        this.selectedMember = -1;
      },
      editVisitor(visitor) {
        this.selectedVisitor = this.visitorsubs.indexOf(visitor);
        this.currentVisitor = JSON.parse(JSON.stringify(visitor));
        this.showMemberCreateAlert = false;
        this.showMemberCreateSuccess = false;
        this.currentVisitor.init_email = this.currentVisitor.email;
        this.showVisitorAttendance = true;
      },
      saveVisitor() {
        this.showVisitorError = false;
        if(this.currentVisitor.type == 5){
          if(this.currentVisitor.member_id==0){
            this.visitorErrorText = this.$t('controller.visitor_error3');
            this.showVisitorError = true;
            return;
          }
          this.showVisitorAttendance = false;
          if (this.currentVisitor.member_id > 0) {
            for (var i = 0; i < this.contacts.length; i++) {
              if (this.contacts[i].id == this.currentVisitor.member_id) {
                this.currentVisitor.name = this.contacts[i].only_name;
                this.currentVisitor.company = this.contacts[i].company;
                break;
              }
            }
          }
        }else{
          if(this.currentVisitor.name.length==0){
            this.visitorErrorText = this.$t('controller.visitor_error1');
            this.showVisitorError = true;
            return;
          }
          this.showVisitorAttendance = false;
          if (this.currentVisitor.member_id > 0) {
            for (var j = 0; j < this.members2.length; j++) {
              if (this.members2[j].id == this.currentVisitor.member_id) {
                this.currentVisitor.linked_member = this.members2[j].name;
                break;
              }
            }
          } else {
            this.currentVisitor.member_id = 0;
            this.currentVisitor.linked_member= "";
          }
        }
        
        if (!(this.currentVisitor.id && this.currentVisitor.id > 0)) {
          this.visitorsubs.push(this.currentVisitor);
          this.visitorsubs.sort((a, b) => (a.name> b.name) ? 1 : -1);
          this.selectedVisitor = this.visitorsubs.indexOf(this.currentVisitor);
        }
        this.updateAttendance(this.currentVisitor,true);
      },
      deleteVisitor() {
        var index = this.selectedVisitor;
        this.showVisitorAttendance = false;
        if (index > -1) {
          this.visitorsubs.splice(index, 1);
          this.deleteAttendance(this.currentVisitor.id);
        }
      },
      deleteMember(){
        var index = this.selectedMember;
        this.showMemberAttendance = false;
        if (index > -1) {
            this.attendance_members.splice(index, 1);
            this.deleteAttendance(this.currentMember.id);
        }
      },
      deleteAttendance(id){
          this.$http.post("/api/organization/attendance/delete"+(this.useruid==''? "" : "/byuseruid"), {id:id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.$socket.emit("request_attendance_refresh",{ org: this.permissions.organization.id, id_string: this.id_string});
            this.redoMembersPresent();
          }
        }).catch(() => { });
      },
      clearAttendance(){
          this.$http.post("/api/organization/attendance/clear"+(this.useruid==''? "" : "/byuseruid"), {day: this.day,useruid: this.useruid}).then((response) => {
            if (response.data.success === true) {
              this.visitorsubs = [];
            }
            this.showClearAttendance = false;
          }).catch(() => { });
      },
      setAllPresent(){
          this.$http.post("/api/organization/attendance/setallpresent"+(this.useruid==''? "" : "/byuseruid"), {day: this.day, useruid: this.useruid}).then((response) => {
            if (response.data.success === true) {
              for(var i=0; i<this.visitorsubs.length; i++){
                this.visitorsubs[i].present = 1;
              }
            }
            this.showSetAllPresent = false;
          }).catch(() => { });
      },
      updateAttendance(item,refresh) {
        var am = this.attendance_members.filter(v => v.id == item.id);
        this.selectedMember = am.length>0? this.attendance_members.indexOf(am[0].id) : -1;
        var vs = this.visitorsubs.filter(v => v.id == item.id);
        this.selectedVisitor = vs.length>0? this.visitorsubs.indexOf(vs[0]) : -1;
        item.update_time = this.day;
        item.subscribed = 0;
        item.useruid = this.useruid;
        this.$http.post("/api/organization/attendance/update"+(this.useruid==''? "" : "/byuseruid"), item).then((response) => {
          if (response.data.success === true) {
            item.id = response.data.inserted_id;
            item.arrival = response.data.arrival;
            if((item.type == 2 || item.type == -1)){
              if(this.selectedMember>=0){
                this.$set(this.attendance_members, this.selectedMember, item);
              }
            }else if(!(item.type == 2 || item.type == -1)){
                item.visits = response.data.visits;
                if(this.selectedVisitor>=0){
                  this.$set( this.visitorsubs, this.selectedVisitor, item);
                }
            }
            if(refresh){
              this.redoMembersPresent();
            }
            this.$socket.emit("request_attendance_refresh",{ org: this.permissions.organization.id, id_string: this.id_string});
          }
        }).catch((errors) => {console.log(errors);});
      },
      redoMembersPresent(){
        this.members_present = [];
        for(var i=0; i<this.members.length; i++){
          var present = true;
          for(var j=0; j<this.attendance_members.length;j++){
            if(this.members[i].id == this.attendance_members[j].member_id && (this.currentMember && this.currentMember.member_id!=this.members[i].id )){
              present = false;
              break;
            }
          }
          if(present){
            this.members_present.push(this.members[i]);
          }
        }
       
      },
      editMember(member, index) {
        this.selectedMember = index;
        this.currentMember =  JSON.parse(JSON.stringify(member));
        this.redoMembersPresent();
        this.showMemberAttendance = true;
      },
      getMeetingStart() {
        this.$http.post("/api/organization/get/starttime"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.meet_start = response.data.meet_start;
          }
        }).catch(() => { });
      },
       getVisitorHash() {
        this.$http.post("/api/organization/visitorhash/get"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.visitor_hash = response.data.hash;
          }
        }).catch(() => { });
      },
       getVisitorPhotosAllowed() {
        this.$http.post("/api/visitor/get/settings"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.allow_visitor_photos = response.data.allow_visitor_photos;
            if(this.allow_visitor_photos==1 && !this.isCorporateConnections){
              this.fields.push({ key: 'photo_verification',
                  label: this.$t('controller.photo_header'),
                  thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
                  tdClass: "actions-centre td-hover-new"
                })
            }
          }
        }).catch(() => { });
      },
      getVisitorPhoto(visitor) {
        if(visitor.type==3 && visitor.present==1 && visitor.photo_verification>0){
           this.$http.post("/api/visitor/get/photo"+(this.useruid==''? "" : "/byuseruid"), {id: visitor.id, useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            visitor.photo = response.data.photo;
            this.currentVisitor = visitor;
            this.currentVisitor.init_email = this.currentVisitor.email;
            this.showPhotoVerify = true;
          }
        }).catch((e) => { console.log(e)});
        }
       
      },
      createMember(visitor){
        this.showMemberCreateAlert = false;
        this.showMemberCreateSuccess = false;
       
        this.$http.post("/api/visitor/get/photo"+(this.useruid==''? "" : "/byuseruid"), {id: visitor.id, useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            visitor.photo = response.data.photo;
          }
          var member = {  id: 0,access: 0, roles: [], city: "", presence: 0, job_title: "", linkedin_url: visitor.linkedin_url, sponsoring_member_id: 0, preferred_contact: visitor.preferred_contact,  introduction: "",
            run_permission: 0, has_pass: 0, category: visitor.profession, company: visitor.company, email: visitor.email, photo: visitor.photo, photo_small: visitor.photo_small, retype:"", website: visitor.website,
            disabled: 0, password: "", name: visitor.name, sub_role: "", role_description: "", phone: visitor.phone, mobile_phone: visitor.mobile_phone, logo: null, contact_type: "", contact_types: [],ask: "",tagline:"", member_since: ""
          };
          this.$http.post("/api/member/update", member).then((response) => {
              if (response.data.error) {
                if (response.data.error.errno == 1) {
                  var role_conflicts = response.data.error.role_conflicts;
                  this.showMemberCreateAlert = true;
                  this.memberCreateAlertText = this.$t('member.role_error') + ": ";
                  for (var i = 0; i < role_conflicts.length; i++) {
                    this.memberCreateAlertText += role_conflicts[i].name + ", ";
                  }
                  this.memberCreateAlertText = this.memberCreateAlertText.slice(0, this.memberCreateAlertText.length - 2);
                } else if(response.data.error.errno == 1062){
                  this.showMemberCreateAlert = true;
                  this.memberCreateAlertText = this.$t('member.email_error') +": " +response.data.error.conflict.org+" > "+response.data.error.conflict.name;
                }
              }
              else if (response.data.success === true) {
                this.showMemberCreateSuccess = true;
                this.memberCreateAlertText = this.$t('controller.member_created')
              }
            }).catch((errors) => {
              this.$bvToast.show('serverError');
            });
        }).catch((e) => { console.log(e)});
      },
      pullPrevious(visitor){
        if(visitor.init_email != visitor.email){
          this.$http.post("/api/visitor/get/previous"+(this.useruid==''? "" : "/byuseruid"), {email: visitor.email, useruid: this.useruid}).then((response) => {
            if (response.data.success) {
              this.previousInfo = response.data.visitor;
              this.showUsePreviousData = true;
            }
          }).catch((e) => { console.log(e)});
        }
        visitor.init_email = visitor.email;
      },
      copyPreviousVisitorData(){
        if(this.previousInfo==null){
          return;
        }
        var fields = ["name",  "phone", "website", "profession", "mobile_phone", "preferred_language", "preferred_contact",
          "company", "member_id", "photo_verification", "photo", "photo_small", "linkedin_url"];
          for(var i=0; i<fields.length; i++){
            if(this.currentVisitor[fields[i]] !== undefined && (this.currentVisitor[fields[i]]=== "" || this.currentVisitor[fields[i]]===0 || this.currentVisitor[fields[i]] === null) && this.previousInfo[fields[i]] !== undefined){
              this.currentVisitor[fields[i]] = this.previousInfo[fields[i]];
            }
          }
        this.previousInfo = null;
        this.showUsePreviousData = false;
      },
      verifyPhoto(num){
        if(this.currentVisitor && this.currentVisitor.type==3 && this.currentVisitor.present==1 && this.currentVisitor.photo_verification>0){
           this.$http.post("/api/visitor/update/photoverification"+(this.useruid==''? "" : "/byuseruid"), {id: this.currentVisitor.id, useruid: this.useruid, num: num}).then((response) => {
            if (response.data.success) {
              this.currentVisitor.photo_verification = num;
              this.showPhotoVerify = false;
            }
          }).catch(() => { });
        }
      },
      logout() {
         window.close();
      }
    },
    created() {
      document.title = this.$t('global.document_title_attend') +" "+ this.permissions.organization.name;
      window.addEventListener("resize", this.onResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    mounted() {
      if(this.isCorporateConnections){
        (function() {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = favicon;
        })();
        this.fields= [
          { key: 'name',
            label: this.$t('controller.name_company_header'),
            thStyle: { "background-color":  "#fafafa","color": "#666", "border":"none","font-weight": "300"},
            tdClass: "td-hover-new"
          },
          { key: 'type',
                label: this.$t('controller.type_header'),
                thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
                tdClass: "actions-centre"
              },
              { key: 'inperson_or_virtual',
                label: this.$t('controller.attendance_text'),
                thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
                tdClass: "actions-centre"
              },
          { key: 'status',
            label: this.$t('controller.status_header'),
            thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
            tdClass: "actions-centre td-hover-new"
          },
            { key: 'visits',
              label: this.$t('controller.visit_number'),
              thStyle: { "background-color":  "#fafafa","color": "#666", "text-align":"center", "border":"none","font-weight": "300"},
              tdClass: "actions-centre"
            },
            { key: 'linked_member',
              label: "",
              thStyle: { "background-color": "#fafafa","color": "#666", "border":"none","font-weight": "300" }
            }
        ];
      } 
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
     var self = this;
      window.addEventListener("focus", function(event) {
        if(!self.$route.params.useruid || self.$route.params.useruid==""){
          self.authenticate();
        }
      }, false);
        this.showtype = 5;
        this.attendanceAuth();

      this.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
      this.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
      this.getVisitorPhotosAllowed();
      this.getServerUrl();
      this.getVisitorHash();
      this.getDates();
      this.getMembers();
      this.getMeetingStart();
      this.getLanguages();
      this.$root.$on('paneSizeChange', () => {
          self.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
          self.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
      });
      
    }
  }
</script>
