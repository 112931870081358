<template>
  <div>
 <div v-if="(org_name && org_name.length>0) || alwaysShow" class="top-home-bar" :style="{'grid-template-columns': 'max-content max-content auto max-content'}">
      <div id="home-logo" class="fit-content pre-format" >
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
      <div id="header-general-info">
        <span v-if="windowWidth>500 && org_name.length>0" id="home-chapter-name">{{org_name}}</span>
        <span v-if="windowWidth>500 && user_name.length>0" id="header-username" class="curtail-text" >{{user_name}}</span>
      </div>
      <div  id="header-page-info">
        <div></div>
        <div id="header-page-info-center">
              <div><b>{{title}}</b></div>
              <div v-if="subTitle.length>0" >{{subTitle}}</div>
          <div v-if="showDate && next_meeting != null" id="date-text">{{$d(new Date(next_meeting.split("-").join("/")+' 00:00:01'), 'day_text')}}</div>
        </div>
        <div></div>
      </div>
      <div id="header-exit-wrapper">
        <div class="clickable" v-if="(window && (window.opener != null || window.history.length == 1)) || this.logged_in"  id="portal-exit-button" v-on:click="closeWindow"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
      </div>
      <b-modal centered v-model="showOptionModal" :title="$t('controller.options_text')">
            <div class="center-text">
            <div class="option-title">{{$t('controller.language_text')}}</div>
            <div class="fit-content header-language-options">
              <b-button v-for="language in languageOptions" :key="language.id"  :class="{'action-button' : true, 'active-flag': $i18n.locale == language.abbrev}" class="language-flag"  v-on:click="loadLanguageAsync(language.abbrev)" variant="clear-blue" ><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-button>
            </div>
          </div>
          <div slot="modal-footer" class="fit-content footer-button-style">
            <b-button class="action-button" v-on:click="showOptionModal = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
      </div>
    </div>
</template>
<style scoped lang="scss">

#header-exit-wrapper{
  display:grid;
}
#header-page-info-center{
  font-size: 15px;
  padding: 0px 10px;
}
#header-page-info{
  display:grid;grid-template-rows: auto min-content auto;text-align: center;
}
#header-username{
  color:#000;font-size:14px;padding-left: 10px;white-space: nowrap;    line-height: 20px;
}
#header-general-info{
  display:grid;grid-template-rows: auto auto;text-align: left;
}
</style>
<script>
import favicon from "@/assets/favicon-cc.png";
    export default {
    name: "Header",
    props: {useruid: {type: String, default: ""}, title: {type: String, default: ""}, subTitle: {type: String, default: ""}, speakerhash: {type: String, default: ""},resethash: {type: String, default: ""},
     sectionhash: {type: String, default: ""}, showDate: {type: Boolean, default: true}, alwaysShow: {type: Boolean, default: false},xlogout: {type: Boolean, default: false},byId: {type: Boolean, default: false},showOptions: {type: Boolean, default: false}},
    
  data() {
    return {
      windowWidth: 1000,  
      next_meeting: null,
      org_name: "",
      user_name: "",
      logged_in: false,
      languageOptions: [],
      showOptionModal: false
    };
  },
   mounted() {
      if(this.isCorporateConnections){
        (function() {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = favicon;
      })();
      } 
       if(this.useruid != ''){
           this.getByUid();
       }else if(this.speakerhash != ''){
           this.getBySpeakerHash();
       }else if(this.sectionhash != ''){
           this.getBySectionHash();
       }else if(this.resethash != ''){
           this.getByResetHash();
       }else if (this.byId){
         this.getById();
       }
       if(this.showOptions){
         this.getLanguages();
       }
        this.onResize();
  },
  methods: {
    closeWindow(){
        if(this.xlogout){
           this.signout();
        }else{
          if(window.opener != null || window.history.length == 1){
            window.close()
          }else if(this.logged_in){
            this.signout();
          }
        }
      
    },
    onResize() {
        if(document.getElementById("controller-header")){
          this.windowWidth=document.getElementById("controller-header").parentElement.offsetWidth;
        }
      },
      getById(){
        this.$http.post("/api/organization/headerinfo", { }).then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_meeting;
            this.org_name = response.data.name;
            this.user_name = response.data.user_name;
            this.logged_in = response.data.logged_in;
          } 
        }).catch(() => {});
    },
    getByUid(){
        if(this.useruid && this.useruid.length>0){
             this.$http.post("/api/organization/headerinfo/byuseruid", {useruid: this.useruid }).then(response => {
          if (response.data.success === true) {
            
            this.next_meeting = response.data.next_meeting;
            this.org_name = response.data.name;
            this.user_name = response.data.user_name;
            this.logged_in = response.data.logged_in;
          } 
        }).catch(() => {});
        }
    },
    getBySpeakerHash(){
        this.$http.post("/api/organization/headerinfo/byspeakerhash", {speakerhash: this.speakerhash }).then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_meeting;
            this.org_name = response.data.name;
            this.user_name = response.data.user_name;
            this.logged_in = response.data.logged_in;
          } 
        }).catch(() => {});
    },
    getByResetHash(){
        this.$http.post("/api/organization/headerinfo/byresethash", {resethash: this.resethash }).then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_meeting;
            this.org_name = response.data.name;
            this.user_name = response.data.user_name;
            this.logged_in = response.data.logged_in;
          } 
        }).catch(() => {});
    },
    getBySectionHash(){
        this.$http.post("/api/organization/headerinfo/bysectionhash", {sectionhash: this.sectionhash }).then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_meeting;
            this.org_name = response.data.name;
            this.user_name = response.data.user_name;
            this.logged_in = response.data.logged_in;
          } 
        }).catch(() => {});
    },
    getLanguages(){
        this.$http.post("/api/organization/languages"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            for(var i=0; i<response.data.languages.length; i++){
                this.languageOptions.push(response.data.languages[i]);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
  },
   created(){
      window.addEventListener("resize", this.onResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    }
}
</script>